import csharpimg1 from './csharp.webp'

export const cardList = 
[
    {
        "token": "csharp",
        "name": "C#",
        "img": csharpimg1, 
        "imgClassname": "csharp-img"
    },
    {
        "token": "csharp",
        "name": "C#",
        "img": csharpimg1, 
        "imgClassname": "csharp-img"
    },
    {
        "token": "csharp",
        "name": "C#",
        "img": csharpimg1, 
        "imgClassname": "csharp-img"
    },
    {
        "token": "csharp",
        "name": "C#",
        "img": csharpimg1, 
        "imgClassname": "csharp-img"
    },
    {
        "token": "csharp",
        "name": "C#",
        "img": csharpimg1, 
        "imgClassname": "csharp-img"
    },
    {
        "token": "csharp",
        "name": "C#",
        "img": csharpimg1, 
        "imgClassname": "csharp-img"
    },
]